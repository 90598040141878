import Vue from 'vue'
import Vuex from 'vuex'
import FakeAuth from '../plugins/fakeauth'

Vue.use(Vuex)

const LPUI_VIEW_TYPE = 'LPUI_VIEW_TYPE';

function getViewType() {
  let lsVT = localStorage.getItem(LPUI_VIEW_TYPE)
  if (lsVT === null) return 0;
  return parseInt(lsVT);
}

function setViewType(viewType) {
  localStorage.setItem(LPUI_VIEW_TYPE, viewType.toString());
}

function getTimestamp() {
  let d = new Date();
  return Math.floor(d.getTime() / 1000);
}

export default new Vuex.Store({
  state: {
    nowTimestamp: getTimestamp(),
    host: '',
    hosts: [],
    viewType: getViewType(),
    highlightSha: '',
    googleSignIn: null,
    authenticated: false
  },
  getters: {
    host: state => {
        return state.host
    },
    nowTimestamp: state => {
      return state.nowTimestamp
    },
    hosts: state => {
        return state.hosts
    },
    viewType: state => state.viewType,
    highlightSha: state => state.highlightSha,
    googleSignIn: state => state.googleSignIn,
    authenticated: state => state.authenticated
  },
  mutations: {
    host(state, host) {
      state.host = host
    },
    hosts(state, hosts) {
      state.hosts = hosts
    },
    nowTimestamp(state, nowTimestamp) {
      state.nowTimestamp = nowTimestamp
    },
    viewType(state, viewType) {
      state.viewType = viewType
      setViewType(viewType);
    },
    highlightSha(state, highlightSha) {
      state.highlightSha = highlightSha
    },
    googleSignIn(state, googleSignIn) {
      state.googleSignIn = googleSignIn
    },
    authenticated(state, authenticated) {
      state.authenticated = authenticated
    },
  },
  actions: {
    startNowTimer({commit}) {
      setInterval(() => {
        commit('nowTimestamp', getTimestamp());
      }, 1000)
    },
    authenticated({commit}) {
      commit('authenticated', true);
    },
    signIn({commit}, googleSignIn) {
        commit('googleSignIn', googleSignIn)
        commit('authenticated', true);
    },
    signOut({commit}) {
        commit('googleSignIn', null)
        commit('authenticated', false);
        FakeAuth.fakeAuthLogout();
    }
  },
  modules: {
  }
})
