<template>
    <v-container class="fill-height">
    <v-layout v-if="authenticated">
        <v-row >
            <v-col cols="12">
                <div class="display-1 font-weight-thin">Configuration</div>
            </v-col>
            <v-col cols="12" md="6">
                <v-textarea rows="10" outlined v-model="config" label="hosts" />
            </v-col>
            <v-col cols="12" md="6" >
                <v-textarea rows="10" outlined v-model="aliases" label="aliases" />
            </v-col>
            <v-col cols="12" md="6" >
                <v-textarea rows="10" outlined v-model="proxies" label="proxies" />
            </v-col>
            <v-col cols="12" class="text-right">
                <v-btn @click="$router.replace('/')">Cancel</v-btn>
                <v-btn @click="saveConfig" color="primary ml-2" >Save</v-btn>
            </v-col>
        </v-row>
    </v-layout>
        <v-layout v-else class="fill-height d-flex text-center align-center">
            <v-flex >
                <v-icon color="error" size="128">mdi-account</v-icon>
                <div class="display-1">Not signed in</div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "ConfigPage",
    data() {
        return {
            aliases: '',
            proxies: '',
            config: '',
        }
    },
    mounted() {
        this.loadConfig();
    },
    methods: {
        loadConfig() {
            this.$http.get('/api/config')
                .then(response => {
                    this.config = response.data.config;
                    this.aliases = response.data.aliases;
                    this.proxies = response.data.proxies;
                });

        },
        saveConfig() {
            this.$http.post('/api/config', { config: this.config, aliases: this.aliases, proxies: this.proxies })
                .then(() => {
                    this.$router.replace('/');
                });

        }
    }
}
</script>

<style scoped>

</style>
