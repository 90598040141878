<template>
    <div>
        <v-layout :class="hostColor(host)" class="align-center" @contextmenu="show">
            <a :href="hostLink(host)"
               :target="host.domain"
               class="headline d-flex grow white--text text-decoration-none font-weight-thin px-4 py-2">{{ host.name }}
            <v-spacer/>
            </a>
            <v-icon dark left v-if="muted">mdi-volume-off</v-icon>
            <v-progress-circular v-if="loading" class="mr-3" small indeterminate/>
            <slot/>
        </v-layout>
        <v-menu
            v-model="showMenu"
            :position-x="x"
            :position-y="y"
            absolute
            offset-y>
            <v-list>
                <v-list-item>
                    Cancel
                </v-list-item>
                <v-list-item @click="toggleMute()">
                    {{ muted ? 'Unmute' : 'Mute' }}
                </v-list-item>
                <v-list-item @click="getDataForHost">
                    Force update
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
export default {
    name: "HostName",
    props: {
        host: {type: Object, required: true},
        small: {type: Boolean, default: false}
    },
    data: () => ({
        showMenu: false,
        x: 0,
        y: 0,
        loading: false
    }),
    computed: {
        muted() {
            return parseInt(this.host.status.muted) === 1;
        }
    },
    methods: {
        toggleMute() {
            this.mute(!this.muted)
        },
        show(e) {
            e.preventDefault()
            this.showMenu = false
            this.x = e.clientX
            this.y = e.clientY
            this.$nextTick(() => {
                this.showMenu = true
            })
        },
        hostLink(host) {
            return 'https://' + host.domain;
        },
        getDataForHost() {
            this.loading = true;
            this.$http.get('/api/probe/' + this.host.domain)
                .then(response => {
                    console.log('updated domain');
                    this.$store.commit('hosts', response.data)
                })
                .finally(() => {
                    setTimeout(() => {
                        this.loading = false
                    }, 300);
                });
        },
        mute(mute) {
            this.$http.get('/api/mute/' + this.host.domain + '/' + (mute  ? 1 : 0))
                .then(response => {
                    this.$store.commit('hosts', response.data)
                })
        },
    }
}
</script>

<style scoped>

</style>
