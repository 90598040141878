<template>
    <div/>
</template>
<script>
export default {
    name: "DataCollector",
    data() {
        return {
            intervalTimer: null,
            hostsList: [],
        }
    },
    props: {
        interval: { type: Number, default: 60 },
        host: { type: String, default: '' }
    },
    mounted() {
        this.getData();
    },
    beforeDestroy() {
        if (this.intervalTimer !== null) {
            clearTimeout(this.intervalTimer);
            this.intervalTimer = null;
        }
    },
    watch: {
        host: {
            immediate: true,
            handler(newValue) {
                if (this.host === '') {
                    this.$store.commit('host', null);
                } else {
                    this.loadHostInfo(newValue);
                }
            }
        },
        hostsList: {
            immediate: true,
            handler(newValue) {
                this.$store.commit('hosts', newValue);
            }
        }
    },
    methods: {
        getData() {
            this.$http.get('/api/hosts')
            .then(response => {
                this.hostsList = response.data;
            })
            .finally(() => {
                if (this.host !== '') {
                    this.loadHostInfo(this.host);
                }
                this.intervalTimer = setTimeout(this.getData, this.interval * 1000);
            });
        },
        loadHostInfo(host) {
            this.$http.get('/api/host/' + host)
                .then(response => {
                    this.$store.commit('host', response.data);
                });
        }
    }
}
</script>

<style scoped>

</style>
