<template>
    <tr>
        <td width="30%" class="text-no-wrap"><span class="type">{{ type }}</span> {{ getVersion(version) }}</td>
        <td>
            <git-sha :type="type" :expected-sha="expectedSha" :expected-sha-since="expectedShaSince" :version="version" />
        </td>
        <td class="shrink text-right">
            <when :time="version.firstSeen" :absolute="absolute"/>
            <release-age :when="version.firstSeen" />
        </td>
    </tr>
</template>
<script>

import When from "./When";
import ReleaseAge from "./ReleaseAge";
import GitSha from "./GitSha";
export default {
    name: 'host-status-tr',
    components: {GitSha, ReleaseAge, When},
    props: {
        type: {type: String, required: true},
        version: {type: Object, required: true},
        expectedSha: {type: String, default: null},
        expectedShaSince: {type: String, default: -666},
        compact: {type: Boolean, default: false},
        absolute: {type: Boolean, default: false}
    },
}
</script>

<style scoped>
.vue-moments-ago {
    font-size: inherit !important;
}


.type {
    display: inline-block;
    width: 30px;
}

.sha {
}
</style>
