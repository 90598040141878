<template>
    <v-app>
        <v-app-bar app color="primary" dark>

            <v-tooltip right open-delay="100" color="black">
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on" v-bind="attrs">
                        <div class="display-1 font-weight-thin"><span class="hidden-sm-and-down">Bridge </span>Launchpad
                        </div>
                        <div class="version">
                            <span>{{ version }}</span>
                        </div>
                    </div>
                </template>
                {{ fullVersion }}
            </v-tooltip>
            <v-spacer/>


            <v-tooltip bottom open-delay="100" v-if="muted">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip outlined v-bind="attrs" v-on="on" class="mr-4" color="white">{{ muted }}</v-chip>
                </template>
                Muted hosts
            </v-tooltip>


            <v-tooltip bottom open-delay="100" v-if="notCurrent">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-4" color="warning">{{ notCurrent }}</v-chip>
                </template>
                Hosts not running expected version of API
            </v-tooltip>


            <v-tooltip bottom open-delay="100" v-if="notResponding">
                <template v-slot:activator="{ on, attrs }">
                    <v-chip v-bind="attrs" v-on="on" class="mr-4" color="error">{{ notResponding }}</v-chip>
                </template>
                Hosts not responding correctly
            </v-tooltip>


            <div id="google-signin-btn" :class="authenticated? 'd-none' : ''"></div>
            <v-btn outlined small v-if="authenticated" href="#" class="mr-2 white--text text-decoration-none"
                   @click="signOut">Logout
            </v-btn>
            <v-app-bar-nav-icon v-if="authenticated">
                <v-btn @click="$router.push('/history')" icon>
                    <v-icon >mdi-history</v-icon>
                </v-btn>
            </v-app-bar-nav-icon>
            <v-app-bar-nav-icon>
                <v-btn @click="$router.push('/proxies')" icon>
                    <v-icon>mdi-drama-masks</v-icon>
                </v-btn>
            </v-app-bar-nav-icon>
            <v-app-bar-nav-icon>
                <v-btn @click.exact="toggleViewType" @click.ctrl.shift.exact="gotoConfig" icon>
                    <v-icon>{{ viewType == 0 ? 'mdi-table-of-contents' : 'mdi-view-dashboard' }}</v-icon>
                </v-btn>
            </v-app-bar-nav-icon>
        </v-app-bar>
        <v-main>
            <router-view/>
        </v-main>
    </v-app>
</template>

<script>

import FakeAuth from './plugins/fakeauth'

export default {
    name: 'App',
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('startNowTimer');
        // eslint-disable-next-line no-undef
        window.gapi.signin2.render('google-signin-btn', {
            onsuccess: this.onSignIn
        })

        if (FakeAuth.isFakeAuthed()) {
            this.$store.dispatch('authenticated');
        }

    },
    methods: {
        gotoConfig() {
            if (this.$router.currentRoute.path !== '/config') {
                this.$router.push('/config');
            }
        },
        toggleViewType() {
            if (this.$router.currentRoute.path !== '/') {
                this.$router.replace('/');
            } else {
                this.viewType = this.viewType === 0 ? 1 : 0;
            }
        },
        onSignIn(googleUser) {
            var profile = googleUser.getBasicProfile();
            this.$store.dispatch('signIn', profile);
        },
        signOut() {
            // eslint-disable-next-line no-undef
            var auth2 = gapi.auth2.getAuthInstance();
            auth2.signOut().then(() => {
                console.log('User signed out.');
                this.$store.dispatch('signOut');
            });
        },
        lagging(versionBlock, expectedSha) {
            return (expectedSha && expectedSha !== versionBlock.gitsha)
        },
        isMuted(muted) {
            if (muted === undefined) return false;
            if (parseInt(muted) === 1) return true;
            return false;
        }
    },
    computed: {
        muted() {
            let muted = 0;
            this.hosts.forEach((section => {
                section.hosts.forEach(host => {
                    if (this.isMuted(host.status.muted)) {
                        muted++;
                    }
                })
            }))
            return muted;
        },
        notResponding() {
            let nr = 0;
            this.hosts.forEach((section => {
                section.hosts.forEach(host => {
                    if (!this.isMuted(host.status.muted) && parseInt(host.status.responding) === 0) {
                        nr++;
                    }
                })
            }))
            return nr;
        },
        notCurrent() {
            let notCurr = 0;
            this.hosts.forEach((section => {
                section.hosts.forEach(host => {
                    if (!this.isMuted(host.status.muted) &&
                        (this.lagging(host.status.apiVersion, host.expectedSha) || this.lagging(host.status.uiVersion, host.expectedShaUI))) {
                        notCurr++;
                    }
                })
            }))
            return notCurr;
        },
        viewType: {
            get: function () {
                return this.$store.getters['viewType'];
            },
            set: function (newValue) {
                this.$store.commit('viewType', newValue)
            }
        },
        googleSignIn() {
            return this.$store.getters['googleSignIn'];
        },
        authenticated() {
            return this.$store.getters['authenticated'];
        },
        fullVersion() {
            return process.env.CODENAME + ' V' + process.env.VERSION;
        },
        version() {
            return 'V' + process.env.VERSION;
        }
    },
}
;
</script>

<style scoped>
.version {
    position: absolute;
    left: 262px;
    bottom: 8px;
    font-weight: 100;
    font-size: 8px;
    opacity: 0.7;
}
</style>
