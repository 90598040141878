<template>
    <v-container class="grey lighten-1" fluid>
        <data-collector :host="showHostDetailDomain" /><div v-if="cardMode">
            <hosts-section
                @show-host="host => showHostDetail(host)"
                v-for="section in hosts" :key="section.sectionName" :section="section"/>
        </div>
        <div v-else>
            <hosts-table :hosts-info="hosts"
                @show-host="host => showHostDetail(host)"
            />
        </div>
        <v-dialog v-model="showHostDetailDialog" width="90%" >
            <host-detail :host="detailHost" v-if="showHostDetailDialog" @cancel="showHostDetailDialog = false"/>
        </v-dialog>
    </v-container>
</template>

<script>
import DataCollector from "../components/DataCollector";
import HostsSection from "../components/HostsSection";
import HostDetail from "../components/HostDetail";
import HostsTable from "../components/HostsTable";

export default {
    name: 'HomePage',
    components: {HostsTable, HostDetail, HostsSection, DataCollector},
    data() {
        return {
            showHostDetailDialog: false,
            showHostDetailDomain: ''
        }
    },
    methods: {
        showHostDetail(host) {
            this.showHostDetailDomain = host.domain;
            this.showHostDetailDialog = true;
        }
    },
    computed: {
        host() {
            return this.$store.getters['host'];
        },
        cardMode() {
            return this.$store.getters['viewType'] === 0;
        },
        detailHost() {
            if (this.hosts.length) {
                return this.lookupHost(this.showHostDetailDomain);
            }
            return {};
        }
    }
}
</script>

<style>

.api-table {
    width: 100%;
}

.api-table.compact td {
    font-size: 14px !important;
    height: 22px !important;
}

.api-table td {
    font-size: 18px !important;
    height: 34px !important;
}
</style>

