<template>
    <v-container fluid>
        <div class="display-1 font-weight-thin grey--text">Recent Commits</div>
        <v-simple-table>
            <thead>
            <tr>
                <th>Who</th>
                <th>Repo:: Branch</th>
                <th>When</th>
                <th>What</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(event, idx) in history" :key="idx">
                <td>
                    <v-avatar size="32">
                        <v-img :src="event.sender.avatar_url" /></v-avatar>
                    {{ event.sender.login }}</td>
                <td>{{ event.repository.name }}:: {{ event.ref.substring(11) }}</td>
                <td>
                    <div v-if="event.commits.length">
                        <when :time="stringTimeToStamp(event.commits[0].timestamp)" />
                        <release-age :when="stringTimeToStamp(event.commits[0].timestamp)" :max="24 * 3600" :old="8 * 3600"/>
                    </div>
                </td>

                <td>
                    <div v-if="event.commits.length">
                        <a class="text-decoration-none" target="_blank" :href="event.commits[0].url">{{ event.commits[0].message }}</a>
                    </div>
                </td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>

<script>

import ReleaseAge from "@/components/ReleaseAge";
import When from "@/components/When";
export default {
    name: 'HistoryPage',
    components: {When, ReleaseAge},
    data() {
        return {
            history: []
        }
    },
    mounted() {
        this.loadConfig();
    },
    methods: {
        stringTimeToStamp(time) {
            return new Date(time).getTime() / 1000
        },
        loadConfig() {
            this.$http.get('/api/github/history')
                .then(response => {
                    this.history = response.data;
                });
        },
    }
}
</script>

<style>

.api-table {
    width: 100%;
}

.api-table.compact td {
    font-size: 14px !important;
    height: 22px !important;
}

.api-table td {
    font-size: 18px !important;
    height: 34px !important;
}
</style>

