<template>
    <v-card :class="muted ? ' host-muted' : ''">
        <v-card-title class="pa-0 d-flex " :xclass="cardColor">
            <host-name class="grow" :host="host">
                <v-btn class="mx-2" icon dark @click="showHostDetail(host)">
                    <v-icon>{{ iconInformation }}</v-icon>
                </v-btn>
            </host-name>
        </v-card-title>
        <v-card-text class="pa-2 lighten-2" :xclass="cardColor">

            <!--            <div>-->
            <!--                <p> {{ host.status.lastProbe }} {{ host.status.responding }}-->
            <!--                    {{ host.status.lastDuration }}-->
            <!--                    {{ host.status.apiVersion.firstSeen }}</p>-->
            <!--            </div>-->

            <v-simple-table class="api-table grey--text" :class="{ compact: compact }">
                <!--                <thead>-->
                <!--                <tr>-->
                <!--                    <td>Version</td>-->
                <!--                    <td>Git</td>-->
                <!--                    <td>Deploy</td>-->
                <!--                </tr>-->
                <!--                </thead>-->
                <tbody>
                <host-status-tr type="API" :version="host.status.apiVersion" :expected-sha="host.expectedSha" :expected-sha-since="host.status.expectedShaSince" />
                <host-status-tr type="UI" :version="host.status.uiVersion" :expected-sha="host.expectedShaUI" :expected-sha-since="host.status.expectedShaUISince"/>
                </tbody>
            </v-simple-table>

        </v-card-text>
    </v-card>
</template>

<script>

import HostStatusTr from "./HostStatusTr";
import {mdiInformationOutline} from '@mdi/js';
import HostName from "./HostName";

export default {
    name: "HostCard",
    props: {
        host: {type: Object, required: true},
        compact: {type: Boolean, default: false},
    },
    components: {
        HostName,
        HostStatusTr
    },
    methods: {
        showHostDetail(host) {
            this.$emit('show-host', host);
        }
    },
    computed: {
        muted() {
            return parseInt(this.host.status.muted) === 1;
        },
        cardColor() {
            return this.host.status.responding ? 'primary white--text' : 'error white--text';
        },
        iconInformation: () => mdiInformationOutline,
        hostLink() {
            return 'https://' + this.host.domain;
        }
    }
}
</script>

<style>
.v-data-table {
    /*background-color: transparent !important;*/
}

table {
    /*background-color: transparent;*/
}

</style>
