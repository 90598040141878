<template>
    <div id="wrapper">
        <div id="mainChart">
            <apexchart class="response-chart" :key="0" type="area" :series="responseAndUptimeData" height="200" width="100%" :options="mainChartOptions" />
        </div>
<!--        <div id="brushChart">-->
<!--            <apexchart :key="1" type="area" :series="responseDataOnly" height="100" width="100%" :options="brushChartOptions" />-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
    name: "HostChart",
    props: {
        host: {type: Object, required: true}
    },
    methods: {
        getAnnotations(withLabel) {
            const annotations = [];
            this.host.history.api.forEach(hist => {
                annotations.push({
                    x: hist.firstSeen * 1000,
                    strokeDashArray: 0,
                    borderColor: '#000333',
                    label: {
                        borderColor: '#333',
                        style: {
                            color: '#fff',
                            background: '#333',
                            fontSize: '14px',
                        },
                        text: withLabel ? 'V' + hist.version : '-'
                    }
                });
            });
            this.host.history.ui.forEach(hist => {
                annotations.push({
                    x: hist.firstSeen * 1000,
                    strokeDashArray: 0,
                    borderColor: '#0098fc',
                    label: {
                        borderColor: '#0098fc',
                        style: {
                            color: '#fff',
                            background: '#0098fc',
                            fontSize: '14px',
                        },
                        text: withLabel ? 'V' + hist.version : '-'
                    }
                });
            });
            return annotations;
        },
        fillInTimeSeries(series) {
            let newSeries = [];
            let idx = 0;
            let maxIdx = series.length;
            if (maxIdx === 0) {
                return newSeries;
            }
            let loops = 0;

            let previousValue = series[0][1];
            let currTimestamp = series[0][0];

            while (idx < maxIdx) {
                if (series[idx][0] <= currTimestamp) {
                    newSeries.push(series[idx]);
                    previousValue = series[idx][1];
                    idx++;
                } else {
                    newSeries.push([currTimestamp, previousValue]);
                }

                currTimestamp += 60;
                loops++;

                if (loops > 10000) {
                    console.error('fill in time series error', series[idx][0], currTimestamp, series.length, idx, series);
                    break;
                }
            }

            return newSeries;
        },
        generateSeriesFromHistory(history, ind) {
            const upgradeSeries = [];
            const earliestTimestamp = this.host.responseTimes[0][0];
            history.forEach(hist => {
                const upgTimestamp = Math.floor(hist.firstSeen / 60) * 60;
                if (upgTimestamp >= earliestTimestamp) {
                    upgradeSeries.push([upgTimestamp, ind]);
                    upgradeSeries.push([upgTimestamp + 120, 0]);
                }
            });

            return this.fillInTimeSeries(upgradeSeries.sort((a,b) => {
                if (a[0] === b[0]) return 0;
                return (a[0] > b[0]) ? 1 : -1;
            }))
                .map(el => [el[0] * 1000, parseInt(el[1])])

        }
    },
    computed: {
        normaliseUpgradeApiSeries() {
            return this.generateSeriesFromHistory(this.host.history.api, -1000);
        },
        normaliseUpgradeUiSeries() {
            return this.generateSeriesFromHistory(this.host.history.ui, -500);
        },
        normaliseResponseSeries() {
            return this.fillInTimeSeries(this.host.responseTimes).map(el => [el[0] * 1000, parseInt(el[1])])
        },
        normaliseUpTimeSeries() {
            return this.fillInTimeSeries(this.host.upTimes)
                .map(el => [el[0] * 1000, parseInt(el[1]) === 1 ? 0 : 5000])
        },
        responseAndUptimeData() {
            return [
                {
                    name: 'Response Time (ms)',
                    data: this.normaliseResponseSeries
                },
                {
                    name: 'Down Time',
                    data: this.normaliseUpTimeSeries
                },
                {
                    name: 'Update API',
                    data: this.normaliseUpgradeApiSeries
                },
                {
                    name: 'Update UI',
                    data: this.normaliseUpgradeUiSeries
                },
            ];
        },
        responseDataOnly() {
            return [
                {
                    name: 'responseTime',
                    data: this.host.responseTimes.map(el => [el[0] * 1000, parseInt(el[1])])
                }
                ];
        },
        mainChartOptions() {
            return {
                chart: {
                    id: 'mainChart',
                    toolbar: {
                        autoSelected: 'pan',
                        show: false
                    },
                    animations: {
                        enabled: false
                    }
                },
                // annotations: {
                //     xaxis: this.updateAnnotations
                // },
                tooltip: {
                    x: {
                        format: 'MMM dd, HH:mm',
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm'
                        }
                    }
                },
                colors: ['#008FFB', 'rgba(245,8,8,0.8)', '#6bff02', '#780bb8'],
                stroke: {
                    width: [3, 0, 0, 0],
                    curve: ['smooth', 'stepline', 'stepline', 'stepline']
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    type: 'solid',
                    opacity: [0.5,1,1,1]
                },
                markers: {
                    size: 0
                },
                xaxis: {
                    type: 'datetime'
                },
                yaxis: {
                    tickAmount: 6,
                    min: -1000,
                    max: 5000
                }
            };
        },
        brushChartOptions() {
            return {
                chart: {
                    id: 'brushChart',
                    brush: {
                        target: 'mainChart',
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        // xaxis: {
                        //     min: new Date().getTime() - (90 * 24 * 60 * 60 * 1000),
                        //     max: new Date().getTime()
                        // }
                    },
                },
                // annotations: {
                //     xaxis: this.updateAnnotationsNoLabel
                // },
                colors: ['#008FFB'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.91,
                        opacityTo: 0.1,
                    }
                },
                xaxis: {
                    type: 'datetime',
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    min: 1,
                    max: 5000,
                    tickAmount: 2,
                },
                legend: {
                    show: false
                }
            }
        },
        updateAnnotations() {
            return this.getAnnotations(true);
        },
        updateAnnotationsNoLabel() {
            return this.getAnnotations(false);
        }
    }
}

</script>

<style>
#brushChart {
    position: relative;
    margin-top: -40px;
}
.response-chart .apexcharts-yaxis-texts-g text:last-child tspan {
     display: none;
}
</style>
