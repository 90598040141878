<template>
    <div>
        <v-progress-linear height="3" class="mb-1" v-if="!agedOut" :color="colour"
                           background-color="grey lighten-2" :value="ageRange"/>
        <v-progress-linear v-else height="3" class="mb-1" color="yellow" :value="0" background-color="transparent"/>
    </div>
</template>

<script>
export default {
    name: "ReleaseAge",
    props: {
        when: {type: [Number, String], required: true},
        old: {type: Number, default: 2 * 3600},
        max: {type: Number, default: 4 * 3600}
    },
    computed: {
        colour() {
            return (this.age > this.old) ? 'grey' : 'yellow darken-1';
        },
        now() {
            return this.$store.getters['nowTimestamp']
        },
        age() {
            return (this.now - this.whenNormalised);
        },
        whenNormalised() {
            return this.when === '' ? 0 : this.when;
        },
        agedOut() {
            return this.age > this.max;
        },
        ageRange() {
            return 100 - Math.floor((this.age / this.max) * 100);
        }
    }
}
</script>

<style scoped>

</style>
