<script>
export default {
    methods: {
        getVersion(versionBlock) {
            if (versionBlock === undefined || versionBlock === null) {
                return '';
            }
            let v = versionBlock.version || '';
            if (v === '') return '';
            return 'v' + v;
        }
    },
    computed: {
        authenticated() {
            return this.$store.getters['authenticated'];
        },
    }
}
</script>
