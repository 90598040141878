const FAKE_AUTH_KEY = '4aa3f1c0-6cc9-44f2-91a2-8db086d1e3b9';
const FAKE_AUTH_VALUE = '802b7c3f-8a71-4e45-bc21-4fa7f363b3e6';

// To fake an auth
// localStorage.setItem('4aa3f1c0-6cc9-44f2-91a2-8db086d1e3b9', '802b7c3f-8a71-4e45-bc21-4fa7f363b3e6')

function fakeAuthLogout() {
    localStorage.removeItem(FAKE_AUTH_KEY);
}

function isFakeAuthed() {
    return localStorage.getItem(FAKE_AUTH_KEY) === FAKE_AUTH_VALUE;
}

export default {
    FAKE_AUTH_KEY: FAKE_AUTH_KEY,
    FAKE_AUTH_VALUE: FAKE_AUTH_VALUE,
    isFakeAuthed: isFakeAuthed,
    fakeAuthLogout: fakeAuthLogout
}
