<template>
    <div class="text-no-wrap my-1">
        <v-tooltip left open-delay="100" color="black" :disabled="noTooltip">
            <template v-slot:activator="{ on, attrs }">
                <a class="text-decoration-none sha mono" :class="textColour"
                   v-bind="attrs" v-on="on"
                   @mouseover="hovered(version.gitsha)"
                   @mouseleave="hovered('')"
                   :href="gitLink" target="lp_github">{{ shortSha(version.gitsha) }}</a>
            </template>
            <span class="display-1 mono">{{ shortSha(version.gitsha) }}</span>
        </v-tooltip>
        <div v-if="expectedSha" class="d-inline">
            <v-avatar v-if="version.gitsha === expectedSha" size="24" color="grey" class="ml-2">
                <v-icon dark small>mdi-check</v-icon>
            </v-avatar>
            <div class="d-inline" v-else>
                <v-avatar size="24" color="error" class="mx-2">
                    <v-icon small
                            dark>mdi-not-equal-variant
                    </v-icon>
                </v-avatar>
                <v-tooltip right open-delay="100" color="black" close-delay="2000">
                    <template v-slot:activator="{ on, attrs }">
                        <a class="text-decoration-none sha mono"
                           v-bind="attrs" v-on="on"
                           :href="gitLinkCompareToExpectedSha" target="lp_github">
                            <span :class="whatColourExpectedSha">
                                {{ shortSha(expectedSha) }}</span>
                            <v-icon right small>mdi-github</v-icon>
                            <v-icon right small color="red" v-if="isPipelineBuggered">mdi-sleep</v-icon>
                        </a>
                    </template>
                    <div class="text-center">
                        <div class="display-1 mono">{{ shortSha(expectedSha) }}</div>
                        <div>
                            <when :time="expectedShaSince" prefix="First Seen: "/>
                        </div>
                        <div class="caption">{{ howLongAgo }}</div>
                    </div>
                </v-tooltip>
            </div>

        </div>
    </div>
</template>

<script>
import When from "@/components/When";

export default {
    name: "GitSha",
    components: {When},
    props: {
        type: {type: String, required: true},
        version: {type: Object, required: true},
        expectedSha: {type: String, default: null},
        expectedShaSince: {type: String, default: -99},
        dark: {type: Boolean, default: false},
        noHover: {type: Boolean, default: false},
        noTooltip: {type: Boolean, default: false},
    },
    methods: {
        hovered(sha) {
            if (this.noHover) {
                // nothing
            } else {
                this.highlightSha = sha
            }
        }
    },
    computed: {
        deployMinutesAllowed() {
            return this.type === 'API' ? 7 : 40;
        },
        isPipelineBuggered() {
            const secondsAgo = this.now - this.expectedShaSince;
            return (secondsAgo > this.deployMinutesAllowed * 60);
        } ,
        whatColourExpectedSha() {
            if (this.isPipelineBuggered) {
                return 'red--text text--lighten-1';
            } else {
                return 'grey--text text--lighten-1';
            }
        },
        howLongAgo() {
            const d = new Date();
            d.setTime(this.expectedShaSince * 1000);
            return d.toLocaleString('en-GB');
        },
        now() {
            return this.$store.getters['nowTimestamp']
        },
        textColour() {
            if (this.version.gitsha === this.highlightSha) {
                return 'highlight-sha black--text';
            }
            return (this.dark ? 'white--text' : 'grey--text');
        },
        gitLink() {
            const project = this.type === 'API' ? 'npb-php-bridge-api' : 'npb-vuejs-bridge-ui';
            return 'https://github.com/lineten/' + project + '/commit/' + this.version.gitsha;
        },
        gitLinkCompareToExpectedSha() {
            const project = this.type === 'API' ? 'npb-php-bridge-api' : 'npb-vuejs-bridge-ui';
            return 'https://github.com/lineten/' + project + '/compare/' + this.version.gitsha + '...' + this.expectedSha;
        },
        highlightSha: {
            get: function () {
                return this.$store.getters['highlightSha']
            },
            set: function (newValue) {
                this.$store.commit('highlightSha', newValue);
            }
        }
    }
}
</script>

<style>
.mono {
    font-family: Consolas, monospace;
    letter-spacing: 0.1em !important;
}

.highlight-sha {
    background-color: chartreuse;
}
</style>
