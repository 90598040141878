<script>
export default {
    methods: {
        hostColor(host) {
            return (parseInt( host.status.responding) ? 'primary white--text' : 'error white--text');
        },
        shortSha(sha, hashSym) {
            if (hashSym === undefined) hashSym = '#';

            if (sha === undefined) return '';
            if (sha === null) return '';
            if (sha === '') return '';
            return hashSym + sha.substring(0, 7);
        },
        lookupHost(domain) {
            let host = this.hosts.reduce((prev, curr) => {
                let host = curr.hosts.reduce((prev, curr) => {
                    if (curr.domain === domain) {
                        return curr;
                    }
                    return prev;
                }, null);
                if (host !== null) {
                    return host;
                }
                return prev;
            }, null)
            return host || {}
        }
    },
    computed: {
        hosts() {
            const hosts = this.$store.getters['hosts'];
            if (this.authenticated || hosts.length === 0) {
                return hosts;
            } else {
                return [hosts[0]];
            }
        }
    }
}
</script>

<style>
.host-muted {
    opacity: 0.3;
}
</style>
