<template>
    <v-card v-if="host.domain">
        <v-card-title class="pa-0" :class="cardColor">
            <div class="white--text text-decoration-none py-2 px-6 grow display-2 font-weight-thin">{{ host.code }}</div>
            <v-spacer />
            <v-btn icon dark class="mr-2" @click="$emit('cancel')"><v-icon large dark>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text class="pa-3">
            <v-row v-if="hostDetail">
                <v-col cols="4" class="text-left pl-6">Domain {{ host.domain }}</v-col>
                <v-col cols="4" class="text-center"><when absolute :time="host.status.lastProbe" prefix="Last Probe" /></v-col>
                <v-col cols="4" class="text-right pr-6">Response Time {{ host.status.lastDuration }}ms</v-col>
                <v-col cols="12" class="py-0">
                    <host-chart :host="hostDetail"/>
                </v-col>
                <v-col cols="12" md="6" class="border-right">
                    <v-layout class="headline font-weight-thin grey white--text align-center px-4" :class="{ 'title' : $vuetify.breakpoint.mobile }">
                        <v-flex>API &mdash; {{ getVersion(host.status.apiVersion) }}</v-flex>
                        <v-spacer/>
                        <v-flex class="shrink">
                        <git-sha dark no-hover no-tooltip :version="host.status.apiVersion" type="API" :expected-sha="host.expectedSha" :expected-sha-since="host.status.expectedShaSince" />
                        </v-flex>
                    </v-layout>
                    <pre class="status-block grey lighten-2 pa-2">{{ host.status.apiVersion.raw}}</pre>
                </v-col>
                <v-col cols="12" md="6"  class="border-right t-4" v-if="$vuetify.breakpoint.mobile">
                    <host-history :history="hostDetail.history.api" type="API"/>
                </v-col>
                <v-col cols="12" md="6" >
                    <v-layout class="headline font-weight-thin grey white--text align-center px-4" :class="{ 'title' : $vuetify.breakpoint.mobile }">
                        <v-flex>UI &mdash; {{ getVersion(host.status.uiVersion) }}</v-flex>
                        <v-spacer/>
                        <v-flex class="shrink">
                            <git-sha dark no-hover no-tooltip :version="host.status.uiVersion" type="UI" :expected-sha="host.expectedShaUI" :expected-sha-since="host.status.expectedShaUISince" />
                        </v-flex>
                    </v-layout>
                    <pre class="status-block grey lighten-2 pa-2">{{ host.status.uiVersion.raw}}</pre>
                </v-col>
                <v-col cols="12" md="6"  class="border-right t-4" v-if="!$vuetify.breakpoint.mobile">
                    <host-history :history="hostDetail.history.api" type="API"/>
                </v-col>
                <v-col cols="12" md="6" >
                    <host-history :history="hostDetail.history.ui" type="UI"/>
                </v-col>
            </v-row>

        </v-card-text>
    </v-card>
</template>

<script>

import {mdiInformationOutline} from '@mdi/js';
import HostHistory from "./HostHistory";
import When from "./When";
import HostChart from "./HostChart";
import GitSha from "./GitSha";

export default {
    name: "HostDetail",
    props: {
        host: {type: Object, required: true},
    },
    components: {
        GitSha,
        HostChart,
        When,
        HostHistory
    },
    computed: {
        cardColor() {
            return this.host.status.responding ? 'primary white--text' : 'error white--text';
        },
        iconInformation: () => mdiInformationOutline,
        hostLink() {
            return 'https://' + this.host.domain;
        },
        hostDetail() {
            return this.$store.getters['host'];
        }
    }
}
</script>

<style>

.status-block {
    min-height: 150px;
}
.api-table td {
    font-size: 12px !important;
    height: 22px !important;
}

.border-right {
    border-right: 1px dotted grey;
}
</style>
