<template>
    <v-tooltip bottom open-delay="100">
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <div v-if="timeAsDate === '1970-01-01T00:00:00.000Z'" class="grey--text font-italic font-weight-thin">untracked</div>
                <div v-else>
                    <span v-if="absolute">{{prefix}} {{ timeAsLocalDate }}</span>
                    <moments-ago v-else class="justify-end" :prefix="prefix" suffix="ago" :date="timeAsDate"
                                 lang="en"/>
                </div>
            </div>
        </template>
        {{ timeAsLocalDate }}
    </v-tooltip>
</template>

<script>
import MomentsAgo from "./MomentsAgo";

export default {
    name: "When",
    components: {MomentsAgo},
    props: {
        time: {type: [String, Number], required: true},
        prefix: {type: String, default: ''},
        absolute: {type: Boolean, default: false}
    },
    computed: {
        timeAsDate() {
            const d = new Date();
            d.setTime(this.time * 1000);
            return d.toISOString();
        },
        timeAsLocalDate() {
            const d = new Date();
            d.setTime(this.time * 1000);
            return d.toLocaleString('en-GB');
        }
    }
}
</script>

<style scoped>

</style>
