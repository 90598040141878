<template>
    <div>
        <v-data-table
            :mobile-breakpoint="0"
            :headers="headers"
            :items="hostsData"
            item-key="domain"
            :item-class="row_classes"
            disable-pagination
            group-by="sectionIdx"
            sort-by="name"
            class="elevation-1 pb-4"
            hide-default-header
            hide-default-footer
            show-group-by>

            <template v-slot:header="props">
                <thead>
                <tr>
                    <template v-for="header in props.props.headers">
                        <th :key="header.value">
                            <span>{{ header.text }}</span>
                            <v-icon @click.stop="props.on.sort(header.value)" small>
                                {{ sortIcon(props.props.options, header) }}
                            </v-icon>
                            <v-icon v-if="header.groupable === undefined || header.groupable"
                                    @click.stop="props.on.group(header.value)" small>mdi-flip-to-back
                            </v-icon>

                        </th>
                    </template>
                </tr>
                </thead>
            </template>

            <template v-slot:item.name="{ item }">
                <host-name :host="item"/>
            </template>

            <template v-slot:item.status.apiVersion.gitsha="{ item }">
                <git-sha type="API" :version="item.status.apiVersion" :expected-sha="item.expectedSha" :expected-sha-since="item.status.expectedShaSince" />
            </template>

            <template v-slot:item.sectionIdx="{ item }">
                {{ hosts[item.sectionIdx].sectionName }}
            </template>

            <template v-slot:item.status.apiVersion.version="{ item }">
                <div class="text-no-wrap">{{ getVersion(item.status.apiVersion) }}</div>
            </template>


            <template v-slot:item.status.uiVersion.version="{ item }">
                <div class="text-no-wrap">{{ getVersion(item.status.uiVersion) }}</div>
            </template>

            <template v-slot:item.status.uiVersion.gitsha="{ item }">
                <git-sha type="UI" :version="item.status.uiVersion" :expected-sha="item.expectedShaUI" :expected-sha-since="item.status.expectedShaUISince" />
            </template>

            <template v-slot:group.header="{ group, groupBy }">
                <div class="white grey--text display-1 text-no-wrap font-weight-thin px-4 py-2">
                    {{ groupHeading(groupBy, group) }}

                </div>
            </template>

            <template v-slot:item.status.apiVersion.firstSeen="{ item }">
                <div class="text-no-wrap">
                    <when :time="item.status.apiVersion.firstSeen"/>
                    <release-age :when="item.status.apiVersion.firstSeen"/>
                </div>
            </template>

            <template v-slot:item.status.uiVersion.firstSeen="{ item }">
                <div class="text-no-wrap">
                    <when :time="item.status.uiVersion.firstSeen"/>
                    <release-age :when="item.status.uiVersion.firstSeen"/>
                </div>
            </template>


            <template v-slot:item.info="{ item }">
                <div class="text-no-wrap">
                    <v-btn class="mx-2" icon @click="showHostDetail(item)">
                        <v-icon>{{ iconInformation }}</v-icon>
                    </v-btn>

                </div>
            </template>

        </v-data-table>
    </div>
</template>

<script>
import GitSha from "./GitSha";
import When from "./When";
import {mdiInformationOutline} from '@mdi/js';
import ReleaseAge from "./ReleaseAge";
import HostName from "./HostName";

export default {
    name: "HostsTable",
    components: {HostName, ReleaseAge, When, GitSha},
    props: {
        hostsInfo: {type: Array, required: true}
    },
    data() {
        return {
            headers: [
                {text: 'Section', align: 'start', value: 'sectionIdx', sortable: true},
                {text: 'Bridge', align: 'start', value: 'name', groupable: false},
                {text: 'API Version', align: 'start', value: 'status.apiVersion.version', cellClass: 'api-cell'},
                {text: 'Git', align: 'start', value: 'status.apiVersion.gitsha', cellClass: 'api-cell'},
                {
                    text: 'Deployed',
                    align: 'start',
                    value: 'status.apiVersion.firstSeen',
                    groupable: false,
                    cellClass: 'api-cell'
                },
                {text: 'UI Version', align: 'start', value: 'status.uiVersion.version', cellClass: 'ui-cell'},
                {text: 'Git', align: 'start', value: 'status.uiVersion.gitsha', cellClass: 'ui-cell'},
                {
                    text: 'Deployed',
                    align: 'start',
                    value: 'status.uiVersion.firstSeen',
                    groupable: false,
                    cellClass: 'ui-cell'
                },
                {text: 'Info', align: 'center', value: 'info', groupable: false, width: 30},
            ],
        }
    },
    computed: {
        hostsData() {
            const result = [];
            this.hostsInfo.forEach((section, idx) => {
                section.hosts.forEach(host => {
                    result.push({section: section.sectionName, sectionIdx: idx, ...host})
                })
            })
            return result;
        },
        iconInformation: () => mdiInformationOutline,
    },
    methods: {
        row_classes(item) {
            if (parseInt(item.status.muted) === 1) {
                return "host-muted"; //can also return multiple classes e.g ["orange","disabled"]
            }
        },
        sortIcon(options, header) {
            if (options.sortBy.length && header.value === options.sortBy[0]) {
                return options.sortDesc[0] ? 'mdi-arrow-up' : 'mdi-arrow-down';
            }
            return 'mdi-circle-small';
        },
        showHostDetail(host) {
            this.$emit('show-host', host);
        },
        groupHeading(groupBy, value) {
            switch (groupBy[0]) {
                case 'sectionIdx':
                    return this.hostsInfo[value].sectionName
                case 'status.apiVersion.version':
                    return 'V' + value;
                case 'status.apiVersion.gitsha':
                case 'status.uiVersion.gitsha':
                    return this.shortSha(value)
                default:
                    return value;
            }
        },
    }
}
</script>

<style>
.ui-cell {
    background-color: #c0c0cf30 !important;
}

</style>

<style scoped>

</style>
