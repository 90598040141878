<template>
    <div>
    <v-simple-table dense class="history-table">
        <thead>
        <tr>
            <th>Version</th>
            <th>Git</th>
            <th class="text-right">Deployed</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="hist in supplementedHistory" :key="hist.firstSeen">
            <td>{{ getVersion(hist) }}</td>
            <td><git-sha no-hover class="d-inline-block" :type="type" :version="hist" />
                <v-tooltip bottom open-delay="100" v-if="hist.previousSha">
                    <template v-slot:activator="{ on, attrs }">
                        <a class="text-decoration-none" :href="gitLinkCompare(hist.previousSha, hist.gitsha)" target="_blank" v-bind="attrs" v-on="on" >
                            <v-icon small right>mdi-file-compare</v-icon>
                        </a>
                    </template>
                    Compare with previous release {{ shortSha(hist.previousSha) }}
                </v-tooltip>
            </td>
            <td class="text-right">
                <when :time="hist.firstSeen" absolute />
            </td>
        </tr>
        </tbody>

    </v-simple-table>
    </div>
</template>

<script>
import When from "./When";
import GitSha from "./GitSha";

export default {
    name: 'host-history',
    components: {GitSha, When},
    props: {
        type: {type: String, required: true},
        history: {type: Array, required: true},
    },
    methods: {
        shortSha(sha) {
            if (sha === undefined || sha === null) return '';
            return '#' + sha.substring(0, 7);
        },
        gitLinkCompare(a,b) {
            const project = this.type === 'API' ? 'npb-php-bridge-api' : 'npb-vuejs-bridge-ui';
            return 'https://github.com/lineten/' + project + '/compare/' + a + '...' + b;
        },

    },
    computed: {
        supplementedHistory() {
            const suppHist = [];
            let prevSha = null;
            this.history.slice().reverse().forEach(hist => {
                hist.previousSha = prevSha;
                suppHist.push(hist);
                prevSha = hist.gitsha;
            })

            return suppHist.reverse();
        },
        firstSeenDate() {
            const d = new Date();
            d.setTime(this.hist.firstSeen * 1000);
            return d.toISOString();
        }
    }
}
</script>

<style>
.history-table th {
    background-color: #9e9e9e;
    color: #fff !important;
    font-weight: 100;
}
</style>
