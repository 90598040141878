import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import Axios from 'axios'
import VueApexCharts from 'vue-apexcharts'
import Hosts from './mixins/hosts';
import Utils from './mixins/utils';
import VueRouter from 'vue-router'
import HomePage from "./pages/HomePage";
import ConfigPage from "./pages/ConfigPage";
import HistoryPage from './pages/HistoryPage'
import ProxiesPage from './pages/ProxiesPage'

Vue.use(VueRouter)
Vue.use(VueApexCharts)
Vue.mixin(Hosts);
Vue.mixin(Utils);

const routes = [
  { path: '/', component: HomePage },
  { path: '/config', component: ConfigPage },
  { path: '/history', component: HistoryPage },
  { path: '/proxies', component: ProxiesPage }
]
const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.prototype.$baseUrl = window.location.protocol + "//" + process.env.VUE_APP_LAUNCHPAD_API_HOST + ':' + process.env.VUE_APP_LAUNCHPAD_API_PORT;
Vue.prototype.$http = Axios.create({baseURL: Vue.prototype.$baseUrl});

tryToStart();

function tryToStart() {
  if (window.gapi === undefined) {
    console.log('.... waiting');
    setTimeout(tryToStart, 100);
    return;
  }
    new Vue({
      vuetify,
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
}
