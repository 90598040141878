<template>
    <v-row>
        <v-col cols="12">
            <div class="display-1 font-weight-thin">{{ section.sectionName }}</div>
        </v-col>
        <v-col cols="12" md="4" v-for="host in section.hosts" :key="host.domain" >
            <host-card :host="host" compact
                       @show-host="host => $emit('show-host', host)"
            />
        </v-col>
    </v-row>
</template>

<script>
import HostCard from "./HostCard";
export default {
    name: "HostsSection",
    components: {HostCard},
    props: {
        section: { type: Object, required: true }
    }
}
</script>

<style scoped>

</style>
