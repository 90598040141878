<template>
    <v-container fluid>
        <div class="display-1 font-weight-thin grey--text">Proxies</div>
        <v-simple-table>
            <thead>
            <tr>
                <th>Proxy</th>
                <th>Instance Domain</th>
                <th>Instance Name</th>
            </tr>
            </thead>
            <tbody>
            </tbody>
            <tbody v-for="section in proxies" :key="section.sectionName">
            <tr><td colspan="3">
                <div class="display-1 font-weight-thin grey--text">{{section.sectionName}}</div>
            </td></tr>
            <tr v-for="proxy in section.hosts" :key="proxy.domain">
                <td>{{ proxy.domain}}</td>
                <td>{{ proxy.status.domain}}</td>
                <td>{{ proxy.status.name}}</td>
            </tr>
            </tbody>
        </v-simple-table>
    </v-container>
</template>

<script>
export default {
    name: 'ProxyPage',
    data() {
        return {
            proxies: []
        }
    },
    mounted() {
        this.loadConfig();
    },
    methods: {
        loadConfig() {
            this.$http.get('/api/proxies')
                .then(response => {
                    this.proxies = response.data;
                });
        },
    }
}
</script>

<style>

.api-table {
    width: 100%;
}

.api-table.compact td {
    font-size: 14px !important;
    height: 22px !important;
}

.api-table td {
    font-size: 18px !important;
    height: 34px !important;
}
</style>

